a{
  color: #333;
  &:hover{
    color: #333;
  }
}
.navbar {
  padding: 0;
  a{
    color: #fff;
    text-decoration: none;
    &:hover{
      color: rgba(255, 255, 255, 0.8);
    }
  }
  .navbar-toggler{
    position: fixed;
    cursor: pointer;
  }
  .navbar-collapse{
    background: rgba(0, 0, 0, 0.9);
      height: 100%;
      height: 100vh;
    &.show {
      display: flex;
      flex-direction: column;
    }
    ul.navbar-nav {
      width: 100%;
      li.nav-icon {
        display: none;
      }
    }
    p {
      font-weight: 100;
      // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-align: center;
      a {
        padding: 0 20px;
        text-decoration: none;
      }
      a + a {
        border-left: 1px solid rgba(255, 255, 255, 0.6);
      }
      line-height: 1;
    }
  }
  @media all and (min-width: 768px) {
    .navbar-toggler{
      top:27px;
      left: 25px;
    }
    .navbar-collapse{
      ul.navbar-nav {
        flex-direction: row;
        justify-content: center;
        padding-top: 30px;
        height: 20vh;
        box-sizing: border-box;
        li {
          a {
            font-weight: 300;
          }
          &.nav-icon {
            display: none;
          }
        }
      }
      p {
        font-size: 5rem;
        padding:23vh 0;
      }
    }
  }
  @media all and (min-width: 992px) {
    .navbar-collapse{
      ul.navbar-nav {
        li {
          font-size: 1.5rem;
          a {
            padding: 0 23px;
          }
        }
      }
    }
  }
  @media all and (max-width: 991px) and (min-width: 768px) {
    .navbar-collapse{
      ul.navbar-nav {
        li {
          font-size: 1rem;
          a {
            padding: 0 13px;
          }
        }
      }
    }
  }
  @media all and (max-width: 768px) {
    .navbar-toggler{
      top:15px;
      left: 10px;
    }
    .navbar-collapse{
      ul.navbar-nav {
        text-align: right;
        padding: 15px 15px 0 0;
      }
      p {
        font-size: 2rem;
        padding:23vh 0 0
      }
    }
  }
}

footer {
  &.bg-faded, .copyright, a {
    color: #fff;
  }
  .footer-nav p a + a {
    border-left: 1px solid rgba(255, 255, 255, 0.6);
  }
  &.bg-faded {
    background: #000;
  }
  .footer-nav {
    li {
      line-height: 1.8;
      a {
        font-weight: 100;
        text-decoration: none;
        &:hover{
          color: rgba(255, 255, 255, 0.8);
        }
      }
      &.nav-icon {
        a {
          padding-right: 0.8rem;
        }
        + li.nav-icon a {
          padding-left: 0.8rem;
        }
      }
    }
    p {
      font-weight: 100;
      // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      line-height: 1;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 40px 0;
      a {
        padding: 0 20px;
        text-decoration: none;
        text-align: center;
        &:hover{
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
  @media all and (min-width: 992px) {
    .footer-nav{
      p {
        font-size: 5rem;
        font-weight: 100;
      }
    }
  }
  @media all and (min-width: 767px) {
    .footer-nav{
      li {
        font-size: 1.2rem;
        i {
          font-size: 1.3em;
        }
        a {
          padding: 0 2rem;
        }
      }
    }
  }
  @media all and (max-width: 991px) {
    .footer-nav{
      p {
        font-size: 3rem;
      }
    }
  }
  @media all and (max-width: 768px) {
    .footer-nav{
      li {
        font-size: 0.9rem;
        i {
          font-size: 1.3em;
        }
      }
    }
  }
  @media all and (max-width: 575px) {
    .footer-nav{
      p {
        font-size: 1.4rem;
        a {
          padding: 0 15px;
        }
      }
    }
  }
}
