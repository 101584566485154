/*works*/
@-webkit-keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

body.works{
  .category-all .catall, .category-0 .cat0, .category-1 .cat1, .category-2 .cat2, .category-3 .cat3 {
    display: block;
    -webkit-animation: show .5s linear 0s forwards;
    animation: show .5s linear 0s forwards;
  }
  .card-block {
    padding: 1.25rem;
  }
  .photos .item img {
    width: 100%;
  }
}

body.works-top {
  .navbar #navbars p, .footer-nav p {
    display: none;
  }
  #headline{
    padding-top:100px;
    a {
      cursor: pointer;
      border-bottom: 1px solid #fff;
      padding-bottom: 3px;
      text-decoration: none;
      &.selected {
        border-bottom-color: #000;
      }
    }
  }
  .card{
    a{
      text-decoration: none;
    }
  }
  .catall {
    a:hover {
      text-decoration: none;
    }
    display: none;
    opacity: 0;
    width: 48%;
    height: auto;
    padding: 5px;
  }
  .card-text {
    font-size: 0.8rem;
  }
  @media (min-width: 768px) {
    .catall {
      width: 33%;
    }
  }
  @media (max-width: 575px) {
    .catall {
      width: 100%;
    }
  }
  @media (max-width: 480px) {
    #headline {
      margin-top: 3rem;
    }
  }
}

.works-detail {
  #page-title {
    color: #fff;
  }
  h1 {
    font-size: 3rem;
    margin: 1rem 0 3rem;
  }
  .photos .item {
    width: 24.8%;
  }
  .col-md-7{
    p{
      line-height: 1.8rem;
    }
  }
  .item-content {
    position: relative;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
    }
  }
  @media (min-width: 576px) {
    #feature {
      width: 100vw;
      height: 100vh;
      background-color: #eee;
      img {
        display: none;
      }
    }
    #headline {
      padding-top: 40px;
    }
  }
  @media (max-width: 575px) {
    #headline {
      padding-top: 15px;
    }
  }
  @media (max-width: 480px) {
    .photos .item {
      width: 50%;
    }
  }
}
