/* home */
body.home {
  #feature {
    background: rgba(0, 0, 0, 0.1);
    overflow: hidden;
    ul.slick {
      margin: 0;
      padding: 0;
      .slick-list {
        margin: 0;
        padding: 0;
        li {
          margin: 0;
          padding: 0;
          position: relative;
          width: 100vw;
          height: 100vh;
          overflow: hidden;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            list-style: none;
          }
          p {
            color: #fff;
            position: absolute;
            bottom: 5px;
            right: 20px;
            text-align: right;
            font-size: .8rem;
            line-height: 1.4;
            strong {
              display: block;
              font-size: 200%;
              font-weight: 400;
            }
          }
        }
      }
      .slick-dots {
        bottom: 15px;
        z-index: 999;
        li {
          width: 12px;
          height: 20px;
          button:before {
            content: '';
            background: #fff;
            color: #fff;
            font-size: 1px;
            width: 1px;
            height: 12px;
            margin: 0 2px;
          }
          &.slick-active button:before {
            width: 3px;
            margin: 0;
          }
        }
      }
    }
  }
  #news {
    h2 {
      margin-top: -2.3rem;
      color: #fff;
      font-size: 1rem;
      padding-bottom: 3.3rem;
      &::before {
        content: "▼ ";
      }
    }
    .item {
      margin-bottom: 1.5rem;
      .card {
        border: 0;
        a {
          color: #000;
          text-decoration: none;
        }
        .card-text p {
          marginb-ttom: 0.5rem;
          font-size: 0.95rem;
        }
      }
    }
    .grid {
      width: 100%;
      margin: 0 -15px;
      .item {
        width: 33.333%;
        padding: 0 1.5% 1%;
        .card-text p img {
          width: auto;
        }
        img {
          width: 100%;
        }
        @media all and (max-width: 1299px) and (min-width: 992px) {
          width: 50%;
        }
        @media all and (max-width: 991px) {
          width: 100%;
        }
      }
      @media all and (max-width: 767px) {
        margin: 0;
      }
    }
  }
}
