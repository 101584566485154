/* about.html */

body.about {
  #lead{
    .copy{
      font-feature-settings : "palt";
      font-size:3.2rem;
    }
    p{
      line-height:2
    }
  }
  th {
    padding: .3rem .8rem .3rem 0;
    font-weight: normal;
    vertical-align: top;
  }
  td {
    padding: .3rem .8rem .3rem 0;
    vertical-align: top;
    ol{
      padding-left:1.6rem;
    }
  }
  #member{
    th {
      width: 3.6rem;
      padding: 0.1rem 0;
    }
    td {
      padding: 0.1rem 0;
    }
  }
  #award{
    table {
      width: 100%;
      margin:.2rem 0 1.2rem;
      td{
        font-size: .9rem;
        text-align: right;
        vertical-align: middle;
      }
      tr{
        border-bottom: 1px dotted #eee;
      }
    }
  }
}
