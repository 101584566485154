.card a img {
  -webkit-transition: all .5s;
  transition: all .5s;
  opacity: 1;
  &:hover {
    opacity: 0.6;
  }
}

a {
  color: #333;
}

a{
  color: #555;
  text-decoration: underline;
}
/* bootstrapを上書き */

.di--p0 {
  .col-sm-6, .col-md-4 {
    margin: 0;
    padding: 0;
  }
}

.badge {
  font-weight: 100;
  font-size: 0.8rem;
  padding: 0.4rem;
  margin-right: 0.5rem;
}

.display-4 {
  line-height: 1.8;
  font-size: 40px;
  font-weight: 100;
}

/* pages */
#headline{
  h2 {
    margin: 4rem 0 2rem;
  }
  @media (min-width: 576px) {
    padding-bottom: 40px;
  }
  @media (max-width: 575px) {
    padding-bottom: 15px;
  }
}


#contents {
  margin-bottom: 300px;
  .section {
    padding: 40px 0;
  }
}

#page-title {
  position: fixed;
  top: 65px;
  left: 40px;
  font-size: 0.7rem;
  z-index: 1030;
  @media all and (max-width: 767px) {
    top: 55px;
    left: 23px;
  }
}


.content {
  margin-top: 100px;
}

/* grid */
.grid {
  position: relative;
  .item {
    display: block;
    width: 48%;
    padding: 5px;
    z-index: 1;
    @media (min-width: 768px) {
      width: 33%;
    }
    @media (max-width: 575px) {
      width: 100%;
    }
  }
}
