@charset "UTF-8";
a {
  color: #333; }
  a:hover {
    color: #333; }

.navbar {
  padding: 0; }
  .navbar a {
    color: #fff;
    text-decoration: none; }
    .navbar a:hover {
      color: rgba(255, 255, 255, 0.8); }
  .navbar .navbar-toggler {
    position: fixed;
    cursor: pointer; }
  .navbar .navbar-collapse {
    background: rgba(0, 0, 0, 0.9);
    height: 100%;
    height: 100vh; }
    .navbar .navbar-collapse.show {
      display: flex;
      flex-direction: column; }
    .navbar .navbar-collapse ul.navbar-nav {
      width: 100%; }
      .navbar .navbar-collapse ul.navbar-nav li.nav-icon {
        display: none; }
    .navbar .navbar-collapse p {
      font-weight: 100;
      text-align: center;
      line-height: 1; }
      .navbar .navbar-collapse p a {
        padding: 0 20px;
        text-decoration: none; }
      .navbar .navbar-collapse p a + a {
        border-left: 1px solid rgba(255, 255, 255, 0.6); }
  @media all and (min-width: 768px) {
    .navbar .navbar-toggler {
      top: 27px;
      left: 25px; }
    .navbar .navbar-collapse ul.navbar-nav {
      flex-direction: row;
      justify-content: center;
      padding-top: 30px;
      height: 20vh;
      box-sizing: border-box; }
      .navbar .navbar-collapse ul.navbar-nav li a {
        font-weight: 300; }
      .navbar .navbar-collapse ul.navbar-nav li.nav-icon {
        display: none; }
    .navbar .navbar-collapse p {
      font-size: 5rem;
      padding: 23vh 0; } }
  @media all and (min-width: 992px) {
    .navbar .navbar-collapse ul.navbar-nav li {
      font-size: 1.5rem; }
      .navbar .navbar-collapse ul.navbar-nav li a {
        padding: 0 23px; } }
  @media all and (max-width: 991px) and (min-width: 768px) {
    .navbar .navbar-collapse ul.navbar-nav li {
      font-size: 1rem; }
      .navbar .navbar-collapse ul.navbar-nav li a {
        padding: 0 13px; } }
  @media all and (max-width: 768px) {
    .navbar .navbar-toggler {
      top: 15px;
      left: 10px; }
    .navbar .navbar-collapse ul.navbar-nav {
      text-align: right;
      padding: 15px 15px 0 0; }
    .navbar .navbar-collapse p {
      font-size: 2rem;
      padding: 23vh 0 0; } }

footer.bg-faded, footer .copyright, footer a {
  color: #fff; }

footer .footer-nav p a + a {
  border-left: 1px solid rgba(255, 255, 255, 0.6); }

footer.bg-faded {
  background: #000; }

footer .footer-nav li {
  line-height: 1.8; }
  footer .footer-nav li a {
    font-weight: 100;
    text-decoration: none; }
    footer .footer-nav li a:hover {
      color: rgba(255, 255, 255, 0.8); }
  footer .footer-nav li.nav-icon a {
    padding-right: 0.8rem; }
  footer .footer-nav li.nav-icon + li.nav-icon a {
    padding-left: 0.8rem; }

footer .footer-nav p {
  font-weight: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0; }
  footer .footer-nav p a {
    padding: 0 20px;
    text-decoration: none;
    text-align: center; }
    footer .footer-nav p a:hover {
      color: rgba(255, 255, 255, 0.8); }

@media all and (min-width: 992px) {
  footer .footer-nav p {
    font-size: 5rem;
    font-weight: 100; } }

@media all and (min-width: 767px) {
  footer .footer-nav li {
    font-size: 1.2rem; }
    footer .footer-nav li i {
      font-size: 1.3em; }
    footer .footer-nav li a {
      padding: 0 2rem; } }

@media all and (max-width: 991px) {
  footer .footer-nav p {
    font-size: 3rem; } }

@media all and (max-width: 768px) {
  footer .footer-nav li {
    font-size: 0.9rem; }
    footer .footer-nav li i {
      font-size: 1.3em; } }

@media all and (max-width: 575px) {
  footer .footer-nav p {
    font-size: 1.4rem; }
    footer .footer-nav p a {
      padding: 0 15px; } }

.card a img {
  -webkit-transition: all .5s;
  transition: all .5s;
  opacity: 1; }
  .card a img:hover {
    opacity: 0.6; }

a {
  color: #333; }

a {
  color: #555;
  text-decoration: underline; }

/* bootstrapを上書き */
.di--p0 .col-sm-6, .di--p0 .col-md-4 {
  margin: 0;
  padding: 0; }

.badge {
  font-weight: 100;
  font-size: 0.8rem;
  padding: 0.4rem;
  margin-right: 0.5rem; }

.display-4 {
  line-height: 1.8;
  font-size: 40px;
  font-weight: 100; }

/* pages */
#headline h2 {
  margin: 4rem 0 2rem; }

@media (min-width: 576px) {
  #headline {
    padding-bottom: 40px; } }

@media (max-width: 575px) {
  #headline {
    padding-bottom: 15px; } }

#contents {
  margin-bottom: 300px; }
  #contents .section {
    padding: 40px 0; }

#page-title {
  position: fixed;
  top: 65px;
  left: 40px;
  font-size: 0.7rem;
  z-index: 1030; }
  @media all and (max-width: 767px) {
    #page-title {
      top: 55px;
      left: 23px; } }

.content {
  margin-top: 100px; }

/* grid */
.grid {
  position: relative; }
  .grid .item {
    display: block;
    width: 48%;
    padding: 5px;
    z-index: 1; }
    @media (min-width: 768px) {
      .grid .item {
        width: 33%; } }
    @media (max-width: 575px) {
      .grid .item {
        width: 100%; } }

/* home */
body.home #feature {
  background: rgba(0, 0, 0, 0.1);
  overflow: hidden; }
  body.home #feature ul.slick {
    margin: 0;
    padding: 0; }
    body.home #feature ul.slick .slick-list {
      margin: 0;
      padding: 0; }
      body.home #feature ul.slick .slick-list li {
        margin: 0;
        padding: 0;
        position: relative;
        width: 100vw;
        height: 100vh;
        overflow: hidden; }
        body.home #feature ul.slick .slick-list li img {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          list-style: none; }
        body.home #feature ul.slick .slick-list li p {
          color: #fff;
          position: absolute;
          bottom: 5px;
          right: 20px;
          text-align: right;
          font-size: .8rem;
          line-height: 1.4; }
          body.home #feature ul.slick .slick-list li p strong {
            display: block;
            font-size: 200%;
            font-weight: 400; }
    body.home #feature ul.slick .slick-dots {
      bottom: 15px;
      z-index: 999; }
      body.home #feature ul.slick .slick-dots li {
        width: 12px;
        height: 20px; }
        body.home #feature ul.slick .slick-dots li button:before {
          content: '';
          background: #fff;
          color: #fff;
          font-size: 1px;
          width: 1px;
          height: 12px;
          margin: 0 2px; }
        body.home #feature ul.slick .slick-dots li.slick-active button:before {
          width: 3px;
          margin: 0; }

body.home #news h2 {
  margin-top: -2.3rem;
  color: #fff;
  font-size: 1rem;
  padding-bottom: 3.3rem; }
  body.home #news h2::before {
    content: "▼ "; }

body.home #news .item {
  margin-bottom: 1.5rem; }
  body.home #news .item .card {
    border: 0; }
    body.home #news .item .card a {
      color: #000;
      text-decoration: none; }
    body.home #news .item .card .card-text p {
      marginb-ttom: 0.5rem;
      font-size: 0.95rem; }

body.home #news .grid {
  width: 100%;
  margin: 0 -15px; }
  body.home #news .grid .item {
    width: 33.333%;
    padding: 0 1.5% 1%; }
    body.home #news .grid .item .card-text p img {
      width: auto; }
    body.home #news .grid .item img {
      width: 100%; }
    @media all and (max-width: 1299px) and (min-width: 992px) {
      body.home #news .grid .item {
        width: 50%; } }
    @media all and (max-width: 991px) {
      body.home #news .grid .item {
        width: 100%; } }
  @media all and (max-width: 767px) {
    body.home #news .grid {
      margin: 0; } }

/* about.html */
body.about #lead .copy {
  font-feature-settings: "palt";
  font-size: 3.2rem; }

body.about #lead p {
  line-height: 2; }

body.about th {
  padding: .3rem .8rem .3rem 0;
  font-weight: normal;
  vertical-align: top; }

body.about td {
  padding: .3rem .8rem .3rem 0;
  vertical-align: top; }
  body.about td ol {
    padding-left: 1.6rem; }

body.about #member th {
  width: 3.6rem;
  padding: 0.1rem 0; }

body.about #member td {
  padding: 0.1rem 0; }

body.about #award table {
  width: 100%;
  margin: .2rem 0 1.2rem; }
  body.about #award table td {
    font-size: .9rem;
    text-align: right;
    vertical-align: middle; }
  body.about #award table tr {
    border-bottom: 1px dotted #eee; }

/*news*/
.page-news .date {
  color: #999;
  margin-right: 1em; }

.news-index #page-main a {
  text-decoration: none; }

/*works*/
@-webkit-keyframes show {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes show {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

body.works .category-all .catall, body.works .category-0 .cat0, body.works .category-1 .cat1, body.works .category-2 .cat2, body.works .category-3 .cat3 {
  display: block;
  -webkit-animation: show .5s linear 0s forwards;
  animation: show .5s linear 0s forwards; }

body.works .card-block {
  padding: 1.25rem; }

body.works .photos .item img {
  width: 100%; }

body.works-top .navbar #navbars p, body.works-top .footer-nav p {
  display: none; }

body.works-top #headline {
  padding-top: 100px; }
  body.works-top #headline a {
    cursor: pointer;
    border-bottom: 1px solid #fff;
    padding-bottom: 3px;
    text-decoration: none; }
    body.works-top #headline a.selected {
      border-bottom-color: #000; }

body.works-top .card a {
  text-decoration: none; }

body.works-top .catall {
  display: none;
  opacity: 0;
  width: 48%;
  height: auto;
  padding: 5px; }
  body.works-top .catall a:hover {
    text-decoration: none; }

body.works-top .card-text {
  font-size: 0.8rem; }

@media (min-width: 768px) {
  body.works-top .catall {
    width: 33%; } }

@media (max-width: 575px) {
  body.works-top .catall {
    width: 100%; } }

@media (max-width: 480px) {
  body.works-top #headline {
    margin-top: 3rem; } }

.works-detail #page-title {
  color: #fff; }

.works-detail h1 {
  font-size: 3rem;
  margin: 1rem 0 3rem; }

.works-detail .photos .item {
  width: 24.8%; }

.works-detail .col-md-7 p {
  line-height: 1.8rem; }

.works-detail .item-content {
  position: relative;
  width: 100%;
  height: 100%; }
  .works-detail .item-content img {
    width: 100%; }

@media (min-width: 576px) {
  .works-detail #feature {
    width: 100vw;
    height: 100vh;
    background-color: #eee; }
    .works-detail #feature img {
      display: none; }
  .works-detail #headline {
    padding-top: 40px; } }

@media (max-width: 575px) {
  .works-detail #headline {
    padding-top: 15px; } }

@media (max-width: 480px) {
  .works-detail .photos .item {
    width: 50%; } }

/* BLOG */
body.blog .item img {
  max-width: 100%; }

body.blog .card a {
  text-decoration: none; }

body.blog .card-block {
  padding: 1.25rem; }

body.blog .card-text {
  font-size: 0.8rem; }

body.blog .card-footer {
  font-size: 11px;
  color: #666; }

body.blog p {
  line-height: 2rem; }

body.blog-list ul.widget-list {
  padding-left: 0; }
  body.blog-list ul.widget-list ul {
    padding-left: 1rem; }
  body.blog-list ul.widget-list li {
    margin-bottom: .4rem;
    list-style: none; }
    body.blog-list ul.widget-list li a {
      text-decoration: none; }
    body.blog-list ul.widget-list li li {
      margin: .3rem 0 0;
      font-size: .9rem; }
      body.blog-list ul.widget-list li li.widget-list-last {
        display: inline-block;
        margin-right: 1rem; }

body.blog-detail .content #headline .meta a {
  text-decoration: none; }

body.blog-detail .content img {
  width: 100%;
  height: auto; }

body.blog-detail .content #page-navigation a {
  border: 1px solid #eee;
  font-size: 0.8rem;
  text-decoration: none; }
