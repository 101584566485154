/* BLOG */

body.blog {
  .item img {
    max-width: 100%;
  }
  .card a{
    text-decoration: none;
  }
  .card-block {
    padding: 1.25rem;
  }
  .card-text {
    font-size: 0.8rem;
  }
  .card-footer{
    font-size: 11px;
    color: #666;
  }
  p {
    line-height: 2rem;
  }
}

body.blog-list ul.widget-list {
  padding-left: 0;
  ul {
    padding-left: 1rem;
  }
  li {
    margin-bottom: .4rem;
    list-style: none;
    a{
      text-decoration: none;
    }
    li {
      margin: .3rem 0 0;
      font-size: .9rem;
      &.widget-list-last {
        display: inline-block;
        margin-right: 1rem;
      }
    }
  }
}

body.blog-detail .content {
  #headline{
    .meta{
      a{
        text-decoration: none;
      }
    }
  }
  img {
    width: 100%;
    height: auto;
  }
  #page-navigation a {
    border: 1px solid #eee;
    font-size: 0.8rem;
    text-decoration: none;
  }
}
