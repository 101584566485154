/*news*/

.page-news .date {
  color: #999;
  margin-right: 1em;
}

.news-index{
  #page-main{
    a{
      text-decoration: none;
    }
  }
}
